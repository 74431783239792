// Variables

$grid-gutter-width-v3: 30px;

$grid-breakpoints-v3: (
  xs: 0,
  sm: 670px,
  md: 1090px,
);

$grid-breakpoints-panel-menu-open-v3: (
  xs: 0,
  sm: map-get($grid-breakpoints-v3, "sm") + 256px,
  md: map-get($grid-breakpoints-v3, "md") + 256px,
);

$grid-breakpoints-panel-menu-closed-v3: (
  xs: 0,
  sm: map-get($grid-breakpoints-v3, "sm") + 72px,
  md: map-get($grid-breakpoints-v3, "md") + 72px,
);

$container-max-widths-v3: (
  sm: 582px,
  md: 1002px,
);

// -----

@mixin make-grid-columns-v3($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {
  // Common properties for all breakpoints
  %grid-column-v3 {
    position: relative;
    width: 100%;
    padding-right: $gutter / 2;
    padding-left: $gutter / 2;
  }

  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    @if $columns > 0 {
      // Allow columns to stretch full width below their breakpoints
      @for $i from 1 through $columns {
        .cl#{$infix}-#{$i} {
          @extend %grid-column-v3;
        }
      }
    }

    .cl#{$infix},
    .cl#{$infix}-auto {
      @extend %grid-column-v3;
    }

    @include media-breakpoint-up($breakpoint, $breakpoints) {
      // Provide basic `.cl-{bp}` classes for equal-width flexbox columns
      .cl#{$infix} {
        flex-basis: 0;
        flex-grow: 1;
        min-width: 0; // See https://github.com/twbs/bootstrap/issues/25410
        max-width: 100%;
      }

      @if $grid-row-columns > 0 {
        @for $i from 1 through $grid-row-columns {
          .rw-cls#{$infix}-#{$i} {
            @include row-cols($i);
          }
        }
      }

      .cl#{$infix}-auto {
        @include make-col-auto();
      }

      @if $columns > 0 {
        @for $i from 1 through $columns {
          .cl#{$infix}-#{$i} {
            @include make-col($i, $columns);
          }
        }
      }

      .ordr#{$infix}-first {
        order: -1;
      }

      .ordr#{$infix}-last {
        order: $columns + 1;
      }

      @for $i from 0 through $columns {
        .ordr#{$infix}-#{$i} {
          order: $i;
        }
      }

      @if $columns > 0 {
        // `$columns - 1` because offsetting by the width of an entire row isn't possible
        @for $i from 0 through ($columns - 1) {
          @if not($infix == "" and $i == 0) {
            // Avoid emitting useless .ofst-0
            .ofst#{$infix}-#{$i} {
              @include make-col-offset($i, $columns);
            }
          }
        }
      }
    }
  }
}

.ctnr {
  @include make-container($grid-gutter-width-v3);
  @include make-container-max-widths($container-max-widths-v3, $grid-breakpoints-v3);
  padding: 0;
}

// 100% wide container at all breakpoints
.ctnr-fld {
  @include make-container($grid-gutter-width-v3);
}

.rw {
  @include make-row($grid-gutter-width-v3);
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .cl,
  > [class*="cl-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

@include make-grid-columns-v3($grid-columns, $grid-gutter-width-v3, $grid-breakpoints-v3);

//.ctnr config - Panel with navbar open
.tb-nav-panel-open:not(.tb-nav-panel-float) {
  .ctnr {
    @include make-container($grid-gutter-width-v3);
    @include make-container-max-widths($container-max-widths-v3, $grid-breakpoints-panel-menu-open-v3);
  }

  @include make-grid-columns-v3($grid-columns, $grid-gutter-width-v3, $grid-breakpoints-panel-menu-open-v3);
}

//.ctnr config - Panel with navbar closed
.tb-nav-panel-closed:not(.tb-nav-panel-float) {
  .ctnr {
    @include make-container($grid-gutter-width-v3);
    @include make-container-max-widths($container-max-widths-v3, $grid-breakpoints-panel-menu-closed-v3);
  }

  @include make-grid-columns-v3($grid-columns, $grid-gutter-width-v3, $grid-breakpoints-panel-menu-closed-v3);
}
